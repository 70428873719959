import { createStore } from 'vuex'

const store = createStore({
  state: {
    auth_user: null,
    token: '',
    authenticated: false,
    user: {},
    roles: '',
    data_pdf: {},
    notifications: [],
    simperNotifications: [],
    commissioningNotifications: [],
    badgeContent: 0,
    filter: {
      start_date: null,
      end_date: null,
      page: 1,
      currentItemsPerPage: 10,
      page_label: 1,
      currentItemsPerPage_label: 10,
      status: [],
    }  
  },
  getters: {
    allNotifications(state) {
      return [...state.simperNotifications, ...state.commissioningNotifications];
    },
  },
  mutations: {
    SET_TOKEN(state, accessToken) {
      state.token = accessToken
      state.authenticated = true
    },
    SET_USER(state, accessUser) {
      state.user = accessUser
    },
    SET_DATA_PDF(state, data_pdf) {
      state.data_pdf = data_pdf
    },
    SET_FILTER(state, filter) {
      state.filter = {...state.filter, ...filter}
    },
    SET_NOTIFICATIONS(state, { simper, commissioning }) {
      state.simperNotifications = simper;
      state.commissioningNotifications = commissioning;
      const total = simper.length + commissioning.length;
      state.badgeContent = total > 9 ? "9+" : total;    
    },
  },
  actions: {
    async getDataPdf(state, payload) {
      state.commit('SET_DATA_PDF', payload)
    },

    async setFilter(state, payload) {
      state.commit('SET_FILTER', payload)
    },

    async setNotification(state, payload) {
      const simper = payload.filter(notif => notif.judul.toLowerCase() === 'SIMPER/KIMPER'.toLowerCase())
      const commissioning = payload.filter(notif => notif.judul.toLowerCase() === "Commissioning Unit".toLowerCase())
      state.commit('SET_NOTIFICATIONS', { simper, commissioning });
    }
  },
  modules: {
  }
});

export default store;